import React, {Component} from 'react'
import './Footer.css'

class Footer extends Component {
    render(){
        return(
            <footer className='foot'>
            <p> © Lili Fuentes | 2024 </p>
            </footer>
        )
    }
}

export default Footer
